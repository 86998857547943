import React, { Component } from 'react';
import { FormLabel, FormGroup } from 'react-bootstrap';
import './TextArea.scss';

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.state = { placeholder: 'Please enter your email message' };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.alternateSelected !== this.props.alternateSelected) {
      if (this.props.alternateSelected) this.setState({ placeholder: 'Please enter your email message including alternate contact details' });
      else this.setState({ placeholder: 'Please enter your email message' });
    }
  }

  handleTextChange(value) {
    this.props.inputChange(this.props.stateMsg, value);
  }

  render() {
    return (
        <FormGroup>
            <FormLabel>{this.props.label}</FormLabel>
            <textarea onChange={(event) => this.handleTextChange(event.target.value)} rows="4" placeholder={this.state.placeholder} required />
        </FormGroup>
    );
  }
}

export default TextArea;
