import React, { Component } from 'react';
import {
  Container, Form, FormText, Button, Row, Col
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './OutOfOfficeFormStyle.scss';

import TimePeriod from '../TimePeriod/TimePeriod.jsx';
import AlternateContactContainer from '../../../Containers/AlternateContact/AlternateContact.jsx';
import DirectReportContainer from '../../../Containers/DirectReport/DirectReport.jsx';
import ToggleQuestion from '../../CommonFormComponents/ToggleQuestion/ToggleQuestion.jsx';
import DialogBox from '../../DialogBox/DialogBox.jsx';
import PopUp from '../../PopUp/PopUp.jsx';

class OutOfOfficeForm extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: { nameRequired: 'Please choose a name from the search list', },
      formValidators: {
        employeeInvalid: false,
        externalInvalid: false,
        internalInvalid: false
      },
      dialogBoxContent: {
        title: 'Confirm',
        message: 'Are you sure you want to submit this out of office form with the following details?',
        primaryBtnText: 'No',
        secondaryBtnText: 'Yes',
      },
    };
    this.closeToast = this.closeToast.bind(this);
  }

  checkFormValid() {
    const {
      directReportSelected, internalContact, internalContactSelected, showExternalMsg, externalContactSelected
    } = this.props;
    const employeeInvalid = !directReportSelected;
    const internalInvalid = internalContact !== '' && !internalContactSelected;
    const externalInvalid = showExternalMsg && internalContact !== '' && !externalContactSelected;
    this.setState({ formValidators: { employeeInvalid, internalInvalid, externalInvalid } });

    if (!employeeInvalid && !internalInvalid && !externalInvalid) {
      this.props.handleConfirmBox();
    }
  }

  handleCancelBtn() {
    this.props.history.push({ pathname: '/' });
  }

  closeToast() {
    this.props.setToast(false, '', '');
  }


  render() {
    return (
        <div>
            <Container className="containerStyle">
                <Form className="OOOForm">
                    <FormText className="formHeading">Set out of office for employee</FormText>
                    <br />
                    <DirectReportContainer
                        stateName="employee"
                        stateEmail="employeeEmail"
                        errorMessage={this.state.formErrors.nameRequired}
                        invalid={this.state.formValidators.employeeInvalid}
                        searchTerm={this.props.employee}
                        inputChange={this.props.inputChange}
                        setToast={this.props.setToast}
                        selected={this.props.directReportSelected}
                    />
                    <TimePeriod
                        inputChange={this.props.inputChange}
                        state={{ ...this.props }}
                    />
                    <FormText className="formSubHeadings">
                        For internal senders
                    </FormText>
                    <AlternateContactContainer
                        invalid={this.state.formValidators.internalInvalid}
                        errorMessage={this.state.formErrors.nameRequired}
                        stateName={'internalContact'}
                        stateMsg={'internalMsg'}
                        stateEmail={'internalEmail'}
                        inputChange={this.props.inputChange}
                        setToast={this.props.setToast}
                        searchTerm={this.props.internalContact}
                        email={this.props.internalEmail}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        selected={this.props.internalContactSelected}
                    />
                    <ToggleQuestion
                        stateName={'showExternalMsg'}
                        inputChange={this.props.inputChange}
                        state={{ ...this.props }}
                    />
                    {this.props.showExternalMsg
                      ? (
                          <div>
                              <FormText className="formSubHeadings">For external senders</FormText>
                              <AlternateContactContainer
                                  invalid={this.state.formValidators.externalInvalid}
                                  errorMessage={this.state.formErrors.nameRequired}
                                  stateName={'externalContact'}
                                  stateMsg={'externalMsg'}
                                  stateEmail={'externalEmail'}
                                  inputChange={this.props.inputChange}
                                  setToast={this.props.setToast}
                                  searchTerm={this.props.externalContact}
                                  email={this.props.externalEmail}
                                  startDate={this.props.startDate}
                                  endDate={this.props.endDate}
                                  selected={this.props.externalContactSelected}
                              />
                          </div>
                      )
                      : null
                        }
                    <Row>
                        <Col>
                            <Button onClick={() => this.handleCancelBtn()} className="default-btn btn-secondary">
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => this.checkFormValid()} className="default-btn btn-primary floatRight">
                                Set out of Office
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <DialogBox
                showBothButtons={true}
                show={this.props.showDialogBox}
                handleConfirmBox={this.props.handleConfirmBox}
                content={this.state.dialogBoxContent}
                handleProceed={this.props.handleProceed}
                loading={this.props.oooPostReqSending}
                state={{ ...this.props }}
            />
            <PopUp toastValue={this.props.toastValue} closeToast={this.closeToast} />
        </div>
    );
  }
}

export default withRouter(OutOfOfficeForm);
