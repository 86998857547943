import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';



import { runWithAdal } from 'react-adal';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = false;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

runWithAdal(authContext, () => {
  ReactDOM.render(<App />, document.getElementById('root'));
  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./App.js', () => {
      // eslint-disable-next-line global-require
      const NextApp = require('./App').default;
      ReactDOM.render(<NextApp />, document.getElementById('root'));
    });
  }
}, DO_NOT_LOGIN);


serviceWorker.unregister();
