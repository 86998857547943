import React, { Component } from 'react';
import './Header.scss';
import myLibertyLogo from '../../Resources/Assets/myliberty-logo-rebrand.svg';
import SubNavBar from './SubNavBar.jsx';

export class Header extends Component {
  render() {
    return (
        <div>
            <div className='HeaderContainer'>
                <a
                    href='https://mylibertyhome.lmig.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <img
                        src={myLibertyLogo}
                        className='MyLibertyLogo'
                        alt='MyLibertyLogo'
                    />
                </a>
            </div>
            <SubNavBar />
        </div>
    );
  }
}

export default Header;
