import React, { Component } from 'react';
import { FormLabel, FormGroup, Button } from 'react-bootstrap';
import DateTime from '../../CommonFormComponents/DateTime/DateTime.jsx';
import './TimePeriod.scss';

class TimePeriod extends Component {
  constructor(props) {
    super(props);
    this.state = { showEndDate: false, };
  }

  toggleEndDate() {
    this.props.inputChange('showEndDate', !this.props.state.showEndDate);
  }

  renderEndDate() {
    if (this.props.state.showEndDate) {
      return (
          <div>
              <DateTime
                  dateStateName={'endDate'}
                  timeStateName={'endTime'}
                  dateLabel={'End Date'}
                  timeLabel={'End Time'}
                  date={this.props.state.endDate}
                  minDate={this.props.state.startDate}
                  time={this.props.state.endTime}
                  {...this.props}
              />
              <Button className='endDateBtn' onClick={() => (this.toggleEndDate())}>Remove end date</Button>
          </div>);
    }
    return <Button className='endDateBtn' onClick={() => (this.toggleEndDate())}>Add end date</Button>;
  }

  render() {
    return (
        <FormGroup>
            <FormLabel>When</FormLabel>
            <DateTime
                dateStateName={'startDate'}
                timeStateName={'startTime'}
                dateLabel={'Start Date'}
                timeLabel={'Start Time'}
                date={this.props.state.startDate}
                minDate={new Date()}
                time={this.props.state.startTime}
                {...this.props}
            />
            {this.renderEndDate()}
        </FormGroup>
    );
  }
}

export default TimePeriod;
