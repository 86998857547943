import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './interceptor.js';
import './Components/LoadingSpinner/LoadingSpinner.jsx';

import DefaultLayout from './Components/DefaultLayout/DefaultLayout.jsx';
import OutOfOfficeForm from './Containers/OutOfOfficeForm/OutOfOfficeForm.jsx';
import Confirmation from './Components/OutOfOffice/Confirmation/Confirmation.jsx';

class App extends React.Component {
  render() {
    return (
        <div className="App">
            <Router>
                <DefaultLayout>
                    <Switch>
                        <Route exact path="/" component={OutOfOfficeForm} />
                        <Route exact path="/OutOfOfficeConfirmation" component={Confirmation} />
                    </Switch>
                </DefaultLayout>
            </Router>
        </div>
    );
  }
}

export default App;
