import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';
import { IconContext } from 'react-icons';
import './Confirmation.css';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { contactNameMsg: '' };
  }

  componentDidMount() {
    const { exterContactName, interContactName } = this.props.history.location;
    if (interContactName !== '') {
      const defaultMsg = 'An email confirmation of this request will be sent to you';
      if (exterContactName === '' || interContactName === exterContactName) {
        this.setState({ contactNameMsg: defaultMsg.concat(` and ${interContactName}`) });
      } else this.setState({ contactNameMsg: defaultMsg.concat(`, ${interContactName} and ${exterContactName}`) });
    }
  }

  setConfirmMsg() {
    if (this.props.history.location.alternateEmailError) {
      return (
          <div>
              <p>Your out of office request was successful.</p>
              <p><b>However we failed to send an email confirmation of this request to the alternate contacts.</b></p>
              <p>Please notify them of these settings</p>
          </div>);
    }
    return <p>{`Your out of office request was successful. ${this.state.contactNameMsg}`}</p>;
  }

  render() {
    return (
        <div>
            <Container className="containerStyle centerContainerContents">
                <Row><Col><h3>Out of Office Request Complete</h3></Col></Row>
                <br />
                <Row className="justify-content-md-center"><Col md={9}>{this.setConfirmMsg()}</Col></Row>
                <Row>
                    <Col>
                        <Link to='/'>
                            <IconContext.Provider value={{ color: '#919191', size: '1.5em' }}>
                                <MdRefresh />
                            </IconContext.Provider>
                            Start another out of office request
                        </Link>
                    </Col>
                </Row>
            </Container>
            <br />
        </div>
    );
  }
}

export default Confirmation;
