import React, { Component } from 'react';
import { FaUser } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import UserService from '../../Services/UserService.js';
import PopUp from '../PopUp/PopUp.jsx';

const axios = require('axios');

export class SecondaryNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      loading: true,
      toastValue: {
        status: false,
        apiMessage: '',
        type: ''
      },
    };
    this.closeToast = this.closeToast.bind(this);
  }

  async componentDidMount() {
    this.getUserName();
  }

  headerLoadingSpinner() {
    return (
        <Spinner animation='border' role='status' >
            <span className='sr-only'>...Loading</span>
        </Spinner>
    );
  }

  closeToast() {
    this.setState({
      ...this.state,
      toastValue: {
        ...this.state.toastValue,
        status: false
      }
    });
  }

    getUserName = async () => {
      const userMail = UserService.getUserMail();
      await axios.get(`/users/${userMail}`).then((res) => {
        this.setState({ loading: false });
        this.setState({ firstName: res.data.displayName.split(',')[1], lastName: res.data.displayName.split(',')[0] });
      }).catch(() => {
        this.setState({
          toastValue: {
            status: true,
            apiMessage: `We can't connect to the server at the minute. Please try again later.`,
            type: 'Error'
          }
        });
      });
    }

    render() {
      return (
          <div className="SecondaryNav">
              { this.state.loading
                ? this.headerLoadingSpinner()
                : <><FaUser /><span>{this.state.firstName} {this.state.lastName}</span></>
              }
              <PopUp toastValue={this.state.toastValue} closeToast={this.closeToast} />
          </div>
      );
    }
}

export default SecondaryNav;
