import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DialogBox.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.jsx';

class DialogBox extends React.Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
  }

  primaryButton() {
    return (
        <Button type="button" className='dialog-btn dialog-btn-white' onClick={this.props.handleConfirmBox}>
            {this.props.content.primaryBtnText}
        </Button>
    );
  }

  secondaryButton() {
    return (
        <Button type="button" className="dialog-btn dialog-btn-secondary" onClick={this.props.handleProceed}>
            {this.props.content.secondaryBtnText}
        </Button>
    );
  }

  renderButtons() {
    if (this.props.loading) {
      return <LoadingSpinner />;
    } if (this.props.showBothButtons) {
      return <>{this.primaryButton()}{this.secondaryButton()}</>;
    }
    return this.primaryButton();
  }

  render() {
    return (
        <Modal show={this.props.show}>
            <Modal.Header className='ModalHeader'>
                <Modal.Title>{this.props.content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='ModalBody'>
                <p>{this.props.content.message}</p>
            </Modal.Body>
            <Modal.Footer className='ModalFooter ModalFooter-centered'>
                <this.renderButtons />
            </Modal.Footer>
        </Modal>
    );
  }
}

export default DialogBox;
