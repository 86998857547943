import React, { Component } from 'react';
import TextArea from '../../CommonFormComponents/TextArea/TextArea.jsx';
import EmployeeDropdown from '../EmployeeDropdown/EmployeeDropdown.jsx';


class AlternateContact extends Component {
  render() {
    return (
        <div>
            <EmployeeDropdown
                mainLabel={'Alternate Contact (optional)'}
                floatLabel={'Employee name lookup'}
                required={false}
                {...this.props}
            />
            <TextArea
                label={'Message'}
                alternateSelected={this.props.selected}
                inputChange={this.props.inputChange}
                stateMsg={this.props.stateMsg}
            />
        </div>
    );
  }
}

export default AlternateContact;
