import React, { Component } from 'react';
import EmployeeDropdown from '../../Components/OutOfOffice/EmployeeDropdown/EmployeeDropdown.jsx';
import UserService from '../../Services/UserService';

const moment = require('moment');
const axios = require('axios');

class DirectReport extends Component {
  constructor(props) {
    super(props);
    this.state = { directReportData: [], loading: true };
    this.getDirectReport = this.getDirectReport.bind(this);
    this.handleContactSelect = this.handleContactSelect.bind(this);
  }

  getFormattedDate(dateTime) {
    const momentFormat = moment(dateTime, 'YYYYMMDDLT');
    const splitFormat = momentFormat.format('MM/DD/YYTHH:mm');
    return splitFormat;
  }

  getDirectReport = async (searchTerm) => {
    this.setState({ loading: true });
    const userMail = UserService.getUserMail();
    await axios.get(`users/${userMail}/directreports?name=${searchTerm}`).then((response) => {
      this.setState({ loading: false });
      if (response.data === 'No direct reports') {
        this.props.setToast(true, `You don't have any direct reports. As a result you will not be able to complete this form`, 'Warning');
      } else {
        this.setState({ directReportData: response.data });
      }
    })
      .catch((error) => {
        this.setState({ loading: false });
        if (!error.response || error.response.status === 400) {
          const errorResponse = `Direct Report: Whoops, there seems to be something wrong with the server `;
          this.props.setToast(true, errorResponse, 'Error');
        } else if (error.response) {
          const errorResponse = `Direct Report: ${error.response.statusText}`;
          this.props.setToast(true, errorResponse, 'Error');
        }
      });
  }

  handleContactSelect(name, email, selectStatus) {
    const { stateName, stateEmail } = this.props;
    this.props.inputChange(stateName, name);
    this.props.inputChange(stateEmail, email);
    this.props.inputChange('directReportSelected', selectStatus);
  }

  render() {
    return (
        <EmployeeDropdown
            loading={this.state.loading}
            mainLabel={'Who'}
            floatLabel={'Employee name lookup'}
            required={true}
            data={this.state.directReportData}
            getSearchResults={this.getDirectReport}
            handleContactSelect={this.handleContactSelect}
            {...this.props}
        />
    );
  }
}

export default DirectReport;
