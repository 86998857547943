import React, { Component } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './ToggleQuestion.scss';

class ToggleQuestion extends Component {
  render() {
    return (
        <div className="questionToggle">
            <p className="question">{this.props.state.toggleQuestion}</p>
            <ToggleButtonGroup className="toggle" defaultValue={this.props.state[this.props.stateName]} type="radio" name={this.props.stateName} defaultChecked={false} onChange={(bool) => this.props.inputChange(this.props.stateName, bool)}>
                <ToggleButton className="btn-secondary" value={true}>Yes</ToggleButton>
                <ToggleButton className="btn-secondary" value={false}>No</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
  }
}

export default ToggleQuestion;
