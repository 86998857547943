import React from 'react';
import './Footer.css';
import logo from '../../Resources/Assets/liberty-mutual-logo-white-rebrand.svg';

class Footer extends React.Component {
  render() {
    return (
        <div className={'footer-body'}>
            <div className={'footer-logo'}>
                <a
                    href={'https://www.libertymutualgroup.com/about-lm/our-company/our-company/'}
                    target={'_blank'}
                >
                    <img
                        src={logo}
                        alt={'Liberty Mutual Logo'}
                    />
                </a>
            </div>
            <section className="footer-copyright">
                <p>
                    <span>©{new Date().getFullYear()} Liberty Mutual Insurance Company</span>
                </p>
                <p>
                    <span>
                        The information contained herein is considered proprietary and may
                        contain trade secret or competitively sensitive information and
                        therefore should not be disseminated outside Liberty Mutual Group.
                    </span>
                </p>
            </section>
            <div className={'footer-social-media-links'}>
                <section>
                    <span>Connect with Liberty Mutual</span>
                </section>
                <div className={'footer-social-icons'}>
                    <a
                        href={'https://www.facebook.com/workatliberty'}
                        title={'Facebook - WorkAtLiberty'}
                        target={'_blank'}
                    >
                        <svg
                            fill={'currentColor'}
                            preserveAspectRatio={'xMidYMid meet'}
                            height={'1em'}
                            width={'1em'}
                            viewBox={'0 0 40 40'}
                            role={'img'}
                        >
                            <g>
                                <path d={'m29.4 0.3v5.9h-3.5q-1.9 0-2.6 0.8t-0.7 2.4v4.2h6.6l-0.9 6.6h-5.7v16.9h-6.8v-16.9h-5.7v-6.6h5.7v-4.9q0-4.1 2.3-6.4t6.2-2.3q3.3 0 5.1 0.3z'} />
                            </g>
                        </svg>
                    </a>
                    <a
                        href={'https://twitter.com/WorkAtLiberty'}
                        title={'Twitter - WorkAtLiberty'}
                        target={'_blank'}
                    >
                        <svg
                            fill={'currentColor'}
                            preserveAspectRatio={'xMidYMid meet'}
                            height={'1em'}
                            width={'1em'}
                            viewBox={'0 0 40 40'}
                            role={'img'}
                        >
                            <g>
                                <path d={'m37.7 9.1q-1.5 2.2-3.7 3.7 0.1 0.3 0.1 1 0 2.9-0.9 5.8t-2.6 5.5-4.1 4.7-5.7 3.3-7.2 1.2q-6.1 0-11.1-3.3 0.8 0.1 1.7 0.1 5 0 9-3-2.4-0.1-4.2-1.5t-2.6-3.5q0.8 0.1 1.4 0.1 1 0 1.9-0.3-2.5-0.5-4.1-2.5t-1.7-4.6v0q1.5 0.8 3.3 0.9-1.5-1-2.4-2.6t-0.8-3.4q0-2 0.9-3.7 2.7 3.4 6.6 5.4t8.3 2.2q-0.2-0.9-0.2-1.7 0-3 2.1-5.1t5.1-2.1q3.2 0 5.3 2.3 2.4-0.5 4.6-1.7-0.8 2.5-3.2 3.9 2.1-0.2 4.2-1.1z'} />
                            </g>
                        </svg>
                    </a>
                    <a
                        href={'https://www.linkedin.com/company/2701/'}
                        title={'Linkedin - Liberty Mutual'}
                        target={'_blank'}
                    >
                        <svg
                            fill={'currentColor'}
                            preserveAspectRatio={'xMidYMid meet'}
                            height={'1em'}
                            width={'1em'}
                            viewBox={'0 0 40 40'}
                            role={'img'}
                        >
                            <g>
                                <path d={'m10.8 13.9v22.2h-7.4v-22.1h7.4z m0.5-6.8q0 1.7-1.2 2.7t-3 1.1h0q-1.9 0-3-1.1t-1.1-2.7q0-1.6 1.2-2.7t3-1.1 2.9 1.1 1.2 2.7z m26 16.3v12.7h-7.4v-11.9q0-2.3-0.9-3.6t-2.8-1.4q-1.4 0-2.3 0.8t-1.5 1.9q-0.2 0.7-0.2 1.8v12.4h-7.3q0-8.9 0-14.5t0-6.6l0-1h7.3v3.2h-0.1q0.5-0.7 1-1.3t1.2-1.1 2-1 2.5-0.4q3.8 0 6.2 2.6t2.3 7.4z'} />
                            </g>
                        </svg>
                    </a>
                    <a
                        href={'https://www.youtube.com/user/LibertyMutual'}
                        title={'YouTube - Liberty Mutual'}
                        target={'_blank'}
                    >
                        <svg
                            fill={'currentColor'}
                            preserveAspectRatio={'xMidYMid meet'}
                            height={'1em'}
                            width={'1em'}
                            viewBox={'0 0 40 40'}
                            role={'img'}
                        >
                            <g>
                                <path d={'m28.6 20q0-0.8-0.7-1.2l-11.4-7.1q-0.7-0.5-1.5-0.1-0.7 0.4-0.7 1.3v14.2q0 0.9 0.7 1.3 0.4 0.2 0.7 0.2 0.5 0 0.8-0.3l11.4-7.1q0.7-0.4 0.7-1.2z m11.4 0q0 2.1 0 3.3t-0.2 3.1-0.5 3.3q-0.4 1.6-1.6 2.7t-2.7 1.3q-5 0.6-15 0.6t-15-0.6q-1.6-0.2-2.8-1.3t-1.5-2.7q-0.3-1.5-0.5-3.3t-0.2-3.1 0-3.3 0-3.3 0.2-3.1 0.5-3.3q0.4-1.6 1.6-2.7t2.7-1.3q5-0.6 15-0.6t15 0.6q1.6 0.2 2.8 1.3t1.5 2.7q0.3 1.5 0.5 3.3t0.2 3.1 0 3.3z'} />
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
  }
}

export default Footer;
