import React, { Component } from 'react';
import OutOfOfficeComponent from '../../Components/OutOfOffice/OutOfOfficeForm/OutOfOfficeForm.jsx';
import UserService from '../../Services/UserService';
import log from '../../loglevel';

const moment = require('moment');
const momentTz = require('moment-timezone');
const axios = require('axios');

export class OutOfOfficeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: '',
      employeeEmail: '',
      directReportSelected: false,
      startDate: new Date(),
      startTime: '9:00',
      endDate: new Date(),
      endTime: '17:00',
      showEndDate: false,
      internalContact: '',
      internalEmail: 'EMAIL',
      internalMsg: '',
      internalContactSelected: false,
      externalContact: '',
      externalEmail: 'EMAIL',
      externalMsg: '',
      externalContactSelected: false,
      toggleQuestion: 'Would you like to create a different message for external senders?',
      showExternalMsg: false,
      oooPostReqSending: false,
      toastValue: {
        status: false,
        apiMessage: '',
        type: ''
      },
      showDialogBox: false,
    };
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleProceed = this.handleProceed.bind(this);
    this.setToast = this.setToast.bind(this);
    this.handleConfirmBox = this.handleConfirmBox.bind(this);
  }

  handleFormInputChange(stateName, value) {
    this.setState({ [stateName]: value });
  }

  getFormattedDateTime(date, time) {
    const dateFormat = moment(date, 'YYYYMMDD');
    const datenewFormat = String(dateFormat.format('YYYY-MM-DD'));
    const timeNewFormat = String(time);
    const addDateTime = `${datenewFormat}' '${timeNewFormat}`;
    const changeFormat = momentTz.tz(addDateTime, this.getBrowserTimezone());
    const getDateTime = changeFormat.format();
    return getDateTime;
  }

  getBrowserTimezone() {
    return momentTz.tz.guess();
  }

  formatDate(date) {
    const dateFormat = moment(date, 'MMDDYY');
    return String(dateFormat.format('MM/DD/YY'));
  }

  handleConfirmationPage(error) {
    const { externalContact, internalContact } = this.state;
    let exterContactName = '';
    if (externalContact) {
      exterContactName = externalContact;
    }
    this.props.history.push({
      pathname: '/OutOfOfficeConfirmation',
      interContactName: internalContact,
      exterContactName,
      alternateEmailError: error
    });
  }

  sendAlternateEmail(extMail) {
    const {
      internalEmail,
      endDate,
      startDate,
      employee,
    } = this.state;

    const emailInfo = {
      managerEmail: UserService.getUserMail(),
      managerName: UserService.getUserName(),
      directReportName: employee,
      internalContact: internalEmail,
      externalContact: extMail,
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate),
      showEndDate: this.state.showEndDate
    };

    axios.post(`mailbox/${this.state.employeeEmail}/alternate-contact-email`, emailInfo).then(() => {
      this.setState({ oooPostReqSending: false });
      this.handleConfirmationPage(false);
    }).catch((error) => {
      log.error(`Error alternate contact failed: ${error}`);
      this.handleConfirmationPage(true);
    });
  }

  handleProceed() {
    const {
      internalMsg,
      internalEmail,
      showExternalMsg,
      externalMsg,
      externalEmail,
      endDate, endTime,
      startTime,
      startDate,
      showEndDate,
      employeeEmail,
      internalContactSelected,
      externalContactSelected
    } = this.state;

    this.setState({ oooPostReqSending: true });
    let extMsg = externalMsg;
    let extMail = externalEmail;
    if (showExternalMsg === false) {
      extMsg = internalMsg;
      extMail = internalEmail;
    }

    const oooForm = {
      externalReplyMessage: extMsg,
      internalReplyMessage: internalMsg,
      scheduledEndDateTime: this.getFormattedDateTime(endDate, endTime),
      scheduledTimeZone: this.getBrowserTimezone(),
      scheduledStartDateTime: this.getFormattedDateTime(startDate, startTime),
      status: 'scheduled',
    };

    if (!showEndDate) {
      const defaultEndDate = moment(startDate).add(2, 'years');
      this.setState({ endDate: defaultEndDate });
      oooForm.scheduledEndDateTime = this.getFormattedDateTime(defaultEndDate, endTime);
    }

    axios.post(`mailbox/${employeeEmail}/automatic-replies-setting`, oooForm).then(() => {
      if (internalContactSelected || externalContactSelected) this.sendAlternateEmail(extMail);
      else this.handleConfirmationPage(false);
    }).catch((error) => {
      log.error(`Error OOO settings failed: ${error}`);
      this.setToast(true, `Failed to submit Out of Office form. Please try again later`, 'Error');
      this.handleConfirmBox();
    });
  }

  setToast(status, apiMessage, type) {
    this.setState({
      toastValue: {
        status,
        apiMessage,
        type
      }
    });
  }

  handleConfirmBox() {
    this.setState({ showDialogBox: !this.state.showDialogBox });
  }

  render() {
    return (
        <OutOfOfficeComponent
            inputChange={this.handleFormInputChange}
            handleProceed={this.handleProceed}
            setToast={this.setToast}
            handleConfirmBox={this.handleConfirmBox}
            {...this.state}
        />
    );
  }
}

export default OutOfOfficeForm;
