import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner.jsx';
import TextInput from '../../CommonFormComponents/TextInput/TextInput.jsx';
import './EmployeeDropdown.scss';

class EmployeeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
      listResult: null,
      selectedUser: null
    };
    this.renderListResult = this.renderListResult.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.loading !== this.props.loading) {
      if (this.state.selectedUser) {
        this.setState({ selectedUser: null });
      }
      this.renderListResult();
    }
  }

  renderListResult() {
    const { loading, data, searchTerm } = this.props;
    let list;
    if (loading) list = <div className="loadingBlock"><LoadingSpinner /></div>;
    else if (data.length && searchTerm.trim() && !this.state.selectedUser) {
      list = this.displayFilteredData();
    } else if (!data.length) {
      list = <div className="noMatch">No matches for '{searchTerm.trim()}'</div>;
    }
    this.setState({ listResult: list });
  }

  OuOfOfficeStatus(status) {
    return status === 'enabled' ? <td className="oooStatusOn">Set</td> : <td className="oooStatusOff">Not Set</td>;
  }

  formatName(fullName) {
    const [lastName, firstName] = fullName.split(',');
    return `${firstName} ${lastName}`.trim();
  }

  displayFilteredData() {
    return (
        <table>
            <tbody>
                {this.props.data.map((item) => (
                    <tr
                        key={item.email}
                        onClick={() => this.handleCheck(item.name, item.email, item.status)}
                    >{this.props.stateName === 'employee'
                      ? this.OuOfOfficeStatus(item.status)
                      : null
                        }
                        <td>
                            <span>{this.formatName(item.name)} </span>
                            <small>{item.email}</small>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
  }

  handleCheck(selectedUser, selectedUserEmail, selectedUserStatus) {
    if (this.props.stateName === 'employee' && selectedUserStatus === 'enabled') {
      const oooOverrideMsg = `${this.formatName(selectedUser)} out of office has already been set. This form will replace their current out of office settings`;
      this.props.setToast(true, oooOverrideMsg, 'Warning');
    }
    this.setState({ listResult: null, selectedUser });
    this.props.handleContactSelect(selectedUser, selectedUserEmail, true);
  }

  render() {
    return (
        <div>
            <Row>
                <Col md={6}>
                    <TextInput
                        triggerList={this.renderListResult}
                        {...this.props}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} style={{ position: 'relative' }}>
                    {this.props.searchTerm.trim()
                      ? (
                          <div className="reportList">
                              {this.state.listResult}
                          </div>
                      )
                      : null}
                </Col>
            </Row>
        </div>
    );
  }
}

export default EmployeeDropdown;
