import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../Header/Header.jsx';
import Footer from '../Footer/Footer.jsx';
import './DefaultLayout.scss';

class DefaultLayout extends React.Component {
  render() {
    return (
        <div>
            <Header />
            <Container>
                <Row>
                    <Col md={12} className='PageContent'>
                        <div>{this.props.children}</div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>

    );
  }
}

export default DefaultLayout;
