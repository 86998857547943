import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import techHelpIcon from '../../Resources/Assets/helpdesk_icon.png';
import SecondaryNav from './SecondaryNav.jsx';

export class SubNavBar extends Component {
  render() {
    return (
        <div className='NavBarBackground'>
            <div className='FlexIt'>
                <div className='TechHelpHeading'>
                    <Navbar.Brand>
                        <img src={techHelpIcon} className='TechHelpIcon' alt='HelpDeskIcon' />
                    </Navbar.Brand>
                    <span className='White Thick'>Technology Help<span className='White Skinny'> EUT </span></span>
                </div>
                <SecondaryNav />
            </div>
        </div>
    );
  }
}

export default SubNavBar;
