import React, { Component } from 'react';
import AlternateContactComponent from '../../Components/OutOfOffice/AlternateContact/AlternateContact.jsx';

const moment = require('moment');
const axios = require('axios');


class AlternateContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alternateData: [],
      loading: true
    };
    this.getEmployeeList = this.getEmployeeList.bind(this);
    this.getUserStatus = this.getUserStatus.bind(this);
    this.handleContactSelect = this.handleContactSelect.bind(this);
  }

  formatDate(date) {
    const newDate = moment(date).format('MM/DD/YYYY');
    return newDate;
  }

  formatName(fullName) {
    const [lastName, firstName] = fullName.split(',');
    return `${firstName} ${lastName}`.trim();
  }

  getUserStatus(user, email) {
    axios.get(`mailbox/${email}/automatic-replies-setting`).then((response) => {
      if (response.data.status === 'alwaysEnabled') {
        const contactAvailabilityMsg = `${this.formatName(user)} has scheduled out of office , you can contact the person to check his availability before assigning.`;
        this.props.setToast(true, contactAvailabilityMsg, 'Warning');
      } else if (response.data.status === 'scheduled') {
        const startDate = this.formatDate(response.data.scheduledStartDateTime.dateTime);
        const endDate = this.formatDate(response.data.scheduledEndDateTime.dateTime);
        const contactAvailabilityMsg = `${this.formatName(user)} has scheduled out of office from ${startDate} to ${endDate}, you can contact the person to check his availability before assigning.`;
        this.props.setToast(true, contactAvailabilityMsg, 'Warning');
      }
    });
  }

  getEmployeeList = async (searchTerm) => {
    this.setState({ loading: true });
    let alternateUrl = `users?name=${searchTerm}`;
    if (searchTerm.includes('@')) {
      alternateUrl = `users?email=${searchTerm}`;
    }
    await axios.get(alternateUrl).then((response) => {
      this.setState({ loading: false, alternateData: response.data });
    }).catch((error) => {
      this.setState({ loading: false });
      if (!error.response || error.response.status === 400) {
        const errorResponse = `Alternate Contact: Whoops, there seems to be something wrong with the server `;
        this.props.setToast(true, errorResponse, 'Error');
      } else if (error.response) {
        const errorResponse = `Alternate Contact: ${error.response.statusText}`;
        this.props.setToast(true, errorResponse, 'Error');
      }
    });
  }

  handleContactSelect(name, email, selectStatus) {
    const { stateName, stateEmail } = this.props;
    this.props.inputChange(stateName, name);
    this.props.inputChange(stateEmail, email);
    this.props.inputChange(`${stateName}Selected`, selectStatus);
  }

  render() {
    return (
        <AlternateContactComponent
            data={this.state.alternateData}
            getSearchResults={this.getEmployeeList}
            loading={this.state.loading}
            getUserStatus={this.getUserStatus}
            handleContactSelect={this.handleContactSelect}
            {...this.props}
        />
    );
  }
}

export default AlternateContactContainer;
